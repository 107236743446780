// Normalize Styles
@import "node_modules/normalize.css/normalize";

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }


.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;

  .center {
    display: block;
    width: 90%;
    max-width: 900px;
    img {
      width: 100%;
      display: block; } } }
